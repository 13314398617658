<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t(CREATE_PAGE_TITLE) }}
      </h3>
    </portal>
    <create-form
      v-if="orderID || isEmpty"
    />
    <div v-else>
      <skeleton />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line import/no-cycle
import mainConfig from '../config'
// eslint-disable-next-line import/no-cycle
import config from '../components/create/direct-request-for-quote/config'
// Components
import CreateForm from '../components/create/direct-request-for-quote/Form.vue'
import Skeleton from '../components/update/direct-request-for-quote/Skeleton.vue'

export default {
  name: 'DirectRequestForQuote',
  components: {
    CreateForm,
    Skeleton,
  },
  data() {
    return {
      isWatchingWarehouse: true,
    }
  },
  computed: {
    ...mapGetters('purchase-cr', ['getTotalDataOfForms']),
    orderID() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm.orderID
    },
    isEmpty() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm?.isEmpty
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['create-form']) {
      this.$refs['create-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(({ data: { data } }) => {
      this.mapDataOnGetItem(data, this)
    })
  },
  setup() {
    const { CREATE_PAGE_TITLE, MODULE_NAME, MODULE_NAME_CLONE } = mainConfig()
    const { customerInformation, mapDataOnGetItem } = config()

    return {
      MODULE_NAME,
      mapDataOnGetItem,
      CREATE_PAGE_TITLE,
      MODULE_NAME_CLONE,
      customerInformation,
    }
  },
}
</script>
